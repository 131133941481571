import Vue from 'vue'
import VueRouter from 'vue-router';
import App from './App.vue'
import $ from 'jquery'
// import '@babel/polyfill'
// import 'core-js/stable';
// import 'regenerator-runtime/runtime'; 
require('@/assets/Site.css')
require('@/assets/magnific-popup.css')
//require('@/js/jquery-3.3.1.min.js')
//require('@/js/jquery.magnific-popup.js')

Vue.config.productionTip = false
Vue.use(VueRouter);

var router = new VueRouter({
    mode: 'history',
    routes: [
    {
        path: '*'
    }
    ]
});
var vm = new Vue({
  render: h => h(App),
  router,
  methods: {
    proccess: function(){
      
    }
  },
  created: function(){
    //console.log('created');
  },
  beforeMount: function() {
    
    //console.log('before mounted');
  },
  mounted: function() {
    //console.log('mounted');
    $('[vp-cloak]').show();
    $('.sadrzaj .animator').animate({ marginLeft: "0px", opacity: "1" }, { duration: 500, complete: function () { } });
  }
})
vm.$mount('#app')